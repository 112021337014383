.popout{
  animation: popout 0.1s linear 1;
}

@keyframes popout{
  50%  {transform: scale(1.5);}
}

@media screen and (max-width: 767px) {   
  #img{
    height: 55%;
  } 
}

@media screen and (min-width: 768px) {      
  #img{      
  	height: 65%;
  } 
} 