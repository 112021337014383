@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400&display=swap');

html{
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(../img/bgBanteng.jpg);
  /* background: url(../img/bgBanteng.jpg); */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  overflow: auto;
  height: 100%;
  /*background: url(../img/bgBanteng.jpg) no-repeat center center fixed;*/
  /*-webkit-background-size: cover;*/
  /*-moz-background-size: cover;*/
  /*-o-background-size: cover;*/
  /*background-size: cover;*/
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#header, #counter{
  font-weight: bold;
  color: white;
  -webkit-text-stroke-color: black;
  font-size: 4.5rem;
}

#header {
  -webkit-text-stroke-width: 2px;
}

#counter {
  -webkit-text-stroke-width: 2.5px;
}